.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 16px;
  background-color: #22172b;
  height: 100vh;
}

.code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 600px;
  width: 100%;

  height: 400px;

  border: 1px solid lightgray;
  border-radius: 5px;
}
